<template>
  <section class="listar-usuario pr-4">
    <vs-row class="mb-5" vs-type="flex" vs-justify="space-between">
      <vs-col class="md:w-1/2 w-full">
        <h3>Lista de usuários</h3>
      </vs-col>

      <vs-col
        class="md:w-1/2 w-full"
        vs-type="flex"
        vs-justify="end"
        v-if="
          profileHelpers.checkPermission([
            profileHelpers.menuFuncionalitiesEnum.GERENCIAR_USUARIOS
          ])
        "
      >
        <vs-button color="primary" type="filled" @click="cadastrarUsuarios">
          Cadastrar Usuário
        </vs-button>
      </vs-col>
    </vs-row>

    <listar-usuarios-component
      v-if="usuarios && usuarios.length"
      :usuarios="usuarios"
      @editarUsuario="editarUsuario"
      @visualizarUsuario="visualizarUsuario"
      @exluirUsuario="loadUsuarios"
    />

    <detalhe-usuario-modal
      v-if="userInfo.UserTypeId"
      :user="usuario"
      :canChange="canChange"
      :showModal="showModal"
      :userTypeId="userInfo.UserTypeId"
      @closeModal="closeModal"
      @loadUsuarios="loadUsuarios"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";
import ListarUsuariosComponent from "@/components/usuario/ListarUsuariosComponent.vue";
import DetalheUsuarioModal from "@/components/usuario/DetalheUsuarioModalComponent.vue";

import PerfilHelpers from "@/helpers/PerfilHelpers";

export default {
  components: { ListarUsuariosComponent, DetalheUsuarioModal },
  directives: { mask },
  data() {
    return {
      showModal: false,
      usuario: {},
      canChange: false,

      profileHelpers: PerfilHelpers
    };
  },
  mounted() {
    this.loadUsuarios();
  },
  computed: {
    ...mapGetters("usuario-module", ["tiposUsuario", "usuarios"]),
    ...mapGetters("auth", ["userInfo"])
  },
  created() {
    this.$appInsights.trackPageView({
      name: "lista-usuarios",
      url: window.location.href
    });
  },
  methods: {
    ...mapActions("usuario-module", ["obterTipoDeAcesso", "obterUsuarios"]),

    async cadastrarUsuarios() {
      this.canChange = true;
      this.showModal = true;
      await this.$onpoint.loading( async () => {
        return await this.obterTipoDeAcesso();
      });
      this.$forceUpdate();
    },

    editarUsuario(user) {
      this.usuario = user;
      this.canChange = true;
      this.showModal = true;

      this.$forceUpdate();
    },

    visualizarUsuario(user) {
      this.usuario = user;
      this.canChange = false;
      this.showModal = true;

      this.$forceUpdate();
    },

    closeModal(value) {
      this.showModal = value;
      this.usuario = {};
    },

   async loadUsuarios() {
      this.$onpoint.loading( async () => {
        return await this.obterUsuarios().catch(errors => {
          this.$onpoint.errorModal(errors.response.data.Errors);
        });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/mixins";

.table-list {
  margin-top: -32px;
  ::v-deep .vs-table--thead {
    tr {
      background: transparent;
    }
  }

  ::v-deep .vs-table--td {
    padding: 31px 15px !important;
    margin-bottom: 1em;
  }

  ::v-deep .vs-table--search {
    max-width: initial;
    margin-bottom: 30px;
    i {
      font-size: 2em;

      @include sm {
        margin-top: -22px;
      }
    }

    input {
      padding-left: 40px !important;
      font-size: 1.4em !important;
      width: 370px;
      border: 0 !important;
      height: 47px;

      @include sm {
        margin-top: -22px;
      }
    }
  }

  ::v-deep .vs-table-text {
    justify-content: center;
  }

  ::v-deep .tr-values {
    margin-bottom: 5px;
    border-left: solid 8px rgba(var(--vs-warning), 1);
    border-bottom: solid 10px #f8f8f8;
  }
}
</style>
