var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "listar-usuario pr-4" },
    [
      _c(
        "vs-row",
        {
          staticClass: "mb-5",
          attrs: { "vs-type": "flex", "vs-justify": "space-between" },
        },
        [
          _c("vs-col", { staticClass: "md:w-1/2 w-full" }, [
            _c("h3", [_vm._v("Lista de usuários")]),
          ]),
          _vm.profileHelpers.checkPermission([
            _vm.profileHelpers.menuFuncionalitiesEnum.GERENCIAR_USUARIOS,
          ])
            ? _c(
                "vs-col",
                {
                  staticClass: "md:w-1/2 w-full",
                  attrs: { "vs-type": "flex", "vs-justify": "end" },
                },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { color: "primary", type: "filled" },
                      on: { click: _vm.cadastrarUsuarios },
                    },
                    [_vm._v("\n        Cadastrar Usuário\n      ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.usuarios && _vm.usuarios.length
        ? _c("listar-usuarios-component", {
            attrs: { usuarios: _vm.usuarios },
            on: {
              editarUsuario: _vm.editarUsuario,
              visualizarUsuario: _vm.visualizarUsuario,
              exluirUsuario: _vm.loadUsuarios,
            },
          })
        : _vm._e(),
      _vm.userInfo.UserTypeId
        ? _c("detalhe-usuario-modal", {
            attrs: {
              user: _vm.usuario,
              canChange: _vm.canChange,
              showModal: _vm.showModal,
              userTypeId: _vm.userInfo.UserTypeId,
            },
            on: { closeModal: _vm.closeModal, loadUsuarios: _vm.loadUsuarios },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }